import React from 'react'
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import SolidHero from "../../components/solid-hero/solid-hero"
import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import TheorieIntensivkursTermine from "../../components/theorie-intensivkurs-termine/theorie-intensivkurs-termine"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"

const KlasseAPage = ({data}) => (
  <Layout>
    <SEO title="Klasse A" description={'Du stehst auf Lack & Leder? Dann mach jetzt den Motorradführerschein Klasse A bei deiner Backnanger Fahrschule Rauscher und hol dir den Kick.'}/>
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={'Motorradführerschein Klasse A'} primary title={'Motorradführerschein'} subtitle={() => <>Klasse A</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview multiColumn text={"Kann schon sein, dass die Antwort auf alle Fragen 42 lautet – außer die auf die Frage nach allen Motorrädern: Die wird mit der 24 beantwortet. Und sobald du dieses Alter erreicht hast, darfst du dich (auch ohne Vorerwerb einer anderen Motorrad-Klasse) für den Führerschein der Klasse A anmelden und dich fortan auf jede Menge Fahrspaß freuen. Denn der A-Führerschein ist die Königsklasse unter den Motorrad-Lizenzen und umschließt auch die Klassen AM, A1 und A2 – also in Summe alle Krafträder und dreirädrigen Kraftfahrzeuge, ohne jegliche Beschränkungen. Das bedeutet für dich eine ungeheure Vielzahl an Möglichkeiten, zukünftig Kilometer zu machen und entspannt über die Straßen zu heizen. Damit das mit Sicherheit auch maximal sicher passiert, gehen wir in den Pflichtstunden alles durch, was du wissen musst, von klassischer Motorrad-Theorie über Fahrpraxis bis hin zu gesonderten Manövern. Bereit?"}>
      <ClassOverviewItem title={'Ausbildung'}>
        <li>Mindestalter: 20 Jahre bei Aufstieg von A2 auf A, 21 Jahre für Trikes, 24 Jahre für Krafträder beim Direkterwerb</li>
        <li>Ausbildung: Theorie und Praxis</li>
        <li>Prüfung: Theorieprüfung und praktische Prüfung</li>
        <li>Bei zweijährigem Vorbesitz der Klasse A2 nur praktische, aber keine theoretische Prüfung erforderlich.</li>
        <li>Eingeschlossene Klassen: AM, A1, A2</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Stundenpensum'}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Überlandfahrt: 5</li>
        <li>Autobahnfahrt: 4</li>
        <li>Dunkelfahrt: 3</li>
        <li>Theorie/Doppelstunden á 90 Minuten bei Ersterteilung: 12 (bei Erweiterung nur 6)</li>
        <li>Zusatzstoff: 4</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Vorraussetzungen'}>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Merkmale'}>
        <li>... Hubraum über 50 cm³ oder bbH über 45 km/h</li>
      </ClassOverviewItem>

      <ClassOverviewItem title={'Weitere Infos'} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <TheorieIntensivkursTermine/>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseAPage

export const query = graphql`
  query KlasseAPageQuery {
    file(relativePath: { eq: "klasse-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
